import React, { useState, useEffect, useCallback, FormEventHandler, FormEvent, useMemo } from 'react';
import Loader from '../Loader/Loader';
import { Cta } from "../Generic/Cta/Cta";
import { CreditCard, PaymentForm } from 'react-square-web-payments-sdk'
import { CheckoutAddress } from "./CheckoutAddress";
import { logSentryError, useCart, useSquareCheckout } from "@chordcommerce/gatsby-theme-autonomy";
import { useMediaQuery } from '@mantine/hooks';


export const CheckoutPayment: React.FC<CheckoutPaymentProps> = ({expanded, user, cart, onSave}) => {

  const { updateOrderPayment, finalizeCheckout, updateOrderDelivery } = useSquareCheckout()
  const [error, setError] = useState('');
  const cartData:ChordCart = cart.cart;
  console.log({cartData})
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit = useCallback(async (e: FormEvent) => {
    e.stopPropagation();
    e.preventDefault();
  }, [])

  const isMobile = useMediaQuery('(max-width: 540px)', true);


  useEffect(() => {
    if (!isSubmitting) return;
    console.log('...is submitting!')
    if (cartData?.isFetching) return;
    console.log({error: cartData?.error, isFetching: cartData?.isFetching})
    if (cartData?.error) {
      setError((cartData.error as any)?.message || 'There was an error saving your contact details');
      setIsSubmitting(false);
      return;
    }
  }, [cartData, isSubmitting, setIsSubmitting])
  return expanded ? (<>
    <div onSubmit={handleSubmit} className={`CheckoutStep CheckoutPayment${isSubmitting ? ' is-loading' : ''}`}>
      <div className="CheckoutStep__loader"><Loader text="Processing payment" /></div>
      {error ? 
           <div className="CheckoutErrors">
            <p className="error">{error}</p>
          </div>
      : null}
      <PaymentForm
          applicationId={process.env.GATSBY_SQUARE_APPLICATION_ID}
          /**
            * Invoked when payment form receives the result of a tokenize generation request.
            * The result will be a valid credit card or wallet token, or an error.
            */
          
          cardTokenizeResponseReceived={(token, buyer) => {
            if (token?.token) {
              (async () => {
                setIsSubmitting(true)
                setError('');
                try {
                  const paymentAttributes:ChordSquarePaymentAttributes = {
                    payment_method_type: 'square',
                    source_attributes: {
                      nonce: token.token
                    }
                  }
                  await updateOrderPayment(paymentAttributes);
                  await (finalizeCheckout as any)({
                    orderNumber: cartData.data.number,
                  });

                } catch(e: any) {
                  (window as any).zz = e
                  try {
                    logSentryError(e)
                  } catch (ee) {
                    console.error(ee)
                  }
                  // alert(e?.message || 'Something went wrong, please try again later.')
                  setError(e?.message || 'Something went wrong, please try again later.')
                  setIsSubmitting(false)
                  return;
                }
                window.location.href = `/order/confirmation/?order=${cartData.data.number}&token=${cartData.data.token}`
              })()
            }
          }}
          locationId={process.env.GATSBY_SQUARE_LOCATION_ID}
          
        >
          <CreditCard
            postalCode={cartData.data.billAddress?.zipcode ? `${cartData.data.billAddress?.zipcode}` : undefined}
            style={{
              '.input-container': {
                borderRadius: isMobile ? '12px' : '99px'
              }
            }}
          />
        </PaymentForm>
        <div className="sub-notice-grid">
          <p className="sub-notice">
            If you’re starting a subscription, your payment method will be
            saved and charged again when your subscription renews. You can
            change your payment method or cancel your subscription in your
            account. If you have any questions, please reach out to <a href="mailto:help@commons.co">help@commons.co</a>.
          </p>
        </div>
    </div>
  </>) : (
    null
  )
}

interface CheckoutPaymentProps {
  user: ChordUser;
  cart: any;
  expanded?: boolean;
  onSave: (data: any) => void;
}