import React, { useCallback, useMemo, useState } from 'react';
import useProductImagesQuery from '~/hooks/graphql/queries/use-product-images';
import { Cta } from '../Generic/Cta/Cta';
import Loader from '../Loader/Loader';
import { StyledCheckoutSummary } from './CheckoutSummary.style';

const CheckoutSummary: React.FC<CheckoutProps> = ({chordCart, user}) => {
  const cart = chordCart?.cart;
  const items = cart?.data?.lineItems || [];
  const [promoField, setPromoField] = useState('');
  const [promoFieldError, setPromoFieldError] = useState('');
  const [isLoading, setLoading] = useState(false);
  const productImages = useProductImagesQuery();
  const [isExpanded, setIsExpanded] = useState(false);
  const isInLoadingState = useMemo(() => {
    return isLoading || (cart?.isFetching || false);
  }, [isLoading, cart]);
  const addPromo = useCallback(async () => {
    if (promoField) {
      setLoading(true);
      try {
        await chordCart.addPromoCode(promoField);
        setPromoField('');
      } catch (error: any) {
        setPromoFieldError(error.message);
      }
      setLoading(false);
    }
  }, [cart, promoField]);

  const removePromo = useCallback(async (code: string) => {
    setLoading(true);
    try {
      await chordCart.removePromoCode(code);
    } catch(e) {
      console.log(e);
    }
    setLoading(false);
  }, [cart]);
  let allAdjustments = useMemo(() => {
    const adjs = [...(cart?.data?.adjustments || [])]
    cart?.data?.lineItems?.forEach((item) => {
      adjs.push(...(item?.adjustments || []));
    });
    return adjs;
  }, [cart]);

  return (
    <StyledCheckoutSummary className={`CheckoutSummary ${isExpanded ? 'is-expanded' : ''}`}>
      <div className={`CheckoutSummary__loader ${isInLoadingState ? 'is-loading' : ''}`}><Loader text="" /></div>
      <h3>
        <button className="CheckoutSummary__expandBtn" onClick={() => setIsExpanded(!isExpanded)}>
          <span>Summary</span> <span className="checkoutSummary__titleTotal">${(+cart?.data?.total || 0).toFixed(2)}</span>
          <span className="checkoutSummary__arrow">
            <svg viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1L5 5L7 3L9 1" stroke="currentColor"/>
            </svg>
          </span>
        </button>
      </h3>
      <div className="CheckoutSummary__items">
        {items.map((item:any, index:number) => {
          const originalPrice = 1 * item.price * item.quantity;
          let finalLinePrice = originalPrice;
          (item.adjustments || []).filter((adj: any) => adj?.sourceType?.includes?.('PromotionAction') && adj?.eligible).forEach((adj: any) => finalLinePrice += (adj.amount as any) * 1);

          let subtitle = '';
          if (item.subscriptionLineItems?.length) {
            subtitle = `Renews every ${item.subscriptionLineItems?.[0]?.interval}`;
          }
          const variant = item?.variant;
          const image = variant.images?.[0]?.smallUrl || (productImages[variant?.sku] ? `${productImages[variant?.sku]}?w=250` : '');
          return (
          <div className="CheckoutSummary__item" key={index}>
            <div className="CheckoutSummary__item__image">
              <img src={image} alt={item.variant?.title || ''} />
              {item.quantity > 1 ? <span className="CheckoutSummary__item__image__quantity">{item.quantity}</span> : null}
            </div>
            <div className="CheckoutSummary__item__info">
              <div className="CheckoutSummary__item__info__title">
                {item.variant?.name || ''}
                <span className="CheckoutSummary__item__info__subtitle">{subtitle}</span>
              </div>
              <div className="CheckoutSummary__item__info__price">
                ${parseFloat(finalLinePrice).toFixed(2)}
                {originalPrice !== finalLinePrice ? (
                  <span className="CheckoutSummary__item__info__price__original">${originalPrice.toFixed(2)}</span>
                ): null}
              </div>
            </div>
          </div>
        )
        })}
      </div>
      <div className="CheckoutSummary__promosContainer">
        <div className="CheckoutSummary__promos">
          <input type="text" id="promoAdd" placeholder='Discount Code' onChange={(e) => [setPromoField(e.target.value), setPromoFieldError('')]} value={promoField} />
          <Cta {...{disabled: isInLoadingState || !promoField}} onClick={addPromo}>Add</Cta>
          {!user?.data?.email && !cart.data.email ? <div className='CheckoutSummary__promos__text'>
            Please enter your email into contact details before entering a promo code.
          </div> : null}
          {promoFieldError && <div className="CheckoutSummary__promos__error">{promoFieldError}</div>}
        </div>
      </div>
      <div className="CheckoutSummary__totals">
        <div className="CheckoutSummary__totalItem">
          <div className="CheckoutSummary__totalItem__title">
            Subtotal
          </div>
          <div className={`CheckoutSummary__totalItem__price`}>
            ${(1 * cart?.data?.itemTotal).toFixed(2)}
          </div>
        </div>
        {
          1 * cart?.data?.adjustmentTotal < 0 && (
            <div className="CheckoutSummary__totalItem">
              <div className="CheckoutSummary__totalItem__title">
                Discount
                {(allAdjustments || []).filter(adj => adj.eligible).filter(adj => 1 * adj.amount !== 0).map((adjustment:any, index:number, arr:any[]) => {
                  const isCurrentIndex = arr.map(v => v.label).indexOf(adjustment.label) === index;
                  return isCurrentIndex ? (
                    <span className="CheckoutSummary__totalItem__subtitle" key={index}>{adjustment.label} {adjustment?.promotionCode?.value && <button className="CheckoutSummary__totalItem__removeBtn" aria-label="Remove this promotion" onClick={() => removePromo(adjustment.promotionCode.value)}>Remove</button>}</span>
                  ): null})}
              </div>
              <div className={`CheckoutSummary__totalItem__price`}>
                -${Math.abs(+cart?.data?.adjustmentTotal - +cart?.data?.taxTotal).toFixed(2)}
              </div>
            </div>
          )
        }
        <div className="CheckoutSummary__totalItem">
          <div className="CheckoutSummary__totalItem__title">
            Shipping
          </div>
          <div className={`CheckoutSummary__totalItem__price ${cart?.data?.shipAddress ? '' : 'is-pending'}`}>
            {cart?.data?.shipAddress ? cart?.data?.displayShipTotal || '' : 'Calculated at next step'}
          </div>
        </div>
        <div className="CheckoutSummary__totalItem">
          <div className="CheckoutSummary__totalItem__title">
            Tax
          </div>
          <div className={`CheckoutSummary__totalItem__price ${cart?.data?.shipAddress ? '' : 'is-pending'}`}>
            {cart?.data?.shipAddress ? cart?.data?.displayTaxTotal || '' : 'Calculated at next step'}
          </div>
        </div>
        <div className="CheckoutSummary__total">
          <div className="CheckoutSummary__total__title">
            Total
          </div>
          <div className="CheckoutSummary__total__price">
            ${(+cart?.data?.total || 0).toFixed(2)}
          </div>
        </div>
        <div className="CheckoutSummary__footer">
          <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="11.5" fill="white" stroke="#D9D9D9"/>
            <path d="M12.5926 14.8598C12.6111 14.1308 12.8704 13.7196 13.5556 13.1776C14.2037 12.6355 14.7778 12.0935 15.2593 11.5514C15.7593 10.9907 16 10.2991 16 9.49533C16 8.56075 15.6852 7.73832 15.0556 7.04673C14.4259 6.35514 13.4259 6 12.0741 6C10.8333 6 9.85185 6.35514 9.11111 7.08411C8.37037 7.81308 8 8.76636 8 9.96262C8 10.3178 8.03704 10.6916 8.11111 11.0654L9.7037 11.1402C9.61111 10.785 9.57407 10.4299 9.57407 10.0748C9.57407 8.35514 10.463 7.3271 12.0741 7.3271C13.5741 7.3271 14.3333 8.31776 14.3333 9.53271C14.3333 10.2243 14.1296 10.7664 13.7407 11.1589C13.3519 11.5514 12.9259 11.9252 12.5 12.2804C11.5185 13.0841 11.1481 13.7944 11.1481 14.8598H12.5926ZM10.8889 18H12.7407V16.1308H10.8889V18Z" fill="#34563B"/>
          </svg>
          <div className="CheckoutSummary__footer__content">
            <div className="CheckoutSummary__footer__title">Need help?</div>
            <div className="CheckoutSummary__footer__text">Reach out to us at (917) 740-8790 or <a href="mailto:help@commons.co">help@commons.co</a></div>
          </div>
        </div>
      </div>
    </StyledCheckoutSummary>
  )
}

interface CheckoutProps {
  chordCart: ReturnType<ChordCartHook>;
  user: ChordUser;
}

export default CheckoutSummary;