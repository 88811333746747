import { useSquareCheckout } from '@chordcommerce/gatsby-theme-autonomy';
import React, { useState, useEffect, useCallback, FormEventHandler, FormEvent, useMemo } from 'react';
import { useCartContext } from '../CartContext/CartContext';
import Loader from '../Loader/Loader';

const formFields =  [
  {
    name: "firstName",
    label: "First Name",
    type: "text",
    required: true
  },
  {
    name: "lastName",
    label: "Last Name",
    type: "text",
    required: true
  },
  {
    name: "phone",
    label: "Phone Number",
    type: "text",
    required: true,
    hideIfLoggedIn: false,
  },
  {
    name: "address1",
    label: "Address",
    type: "text",
    required: true
  },
  {
    name: "address2",
    label: "Address 2",
    type: "text",
    required: false
  },
  {
    name: "city",
    label: "City",
    type: "text",
    required: true
  },
  {
    name: "state",
    label: "State",
    type: "select",
    required: true
  },
  {
    name: "zip",
    label: "Zip Code",
    type: "text",
    required: true
  }
];

export const CheckoutAddress: React.FC<CheckoutAddressProps> = ({expanded, user, cart, onSave, type='shipping'}) => {
  const { isEnrolmentInCart } = useCartContext();
  const { updateOrderAddresses, getStates, updateOrderDelivery } = useSquareCheckout()
  const cartData:ChordCart = cart.cart;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [fields, setFields] = useState<any>({});
  const [states, setStates] = useState<any>(null);
  const [isLoading, setLoading] = useState<boolean>(true);
  const isLoggedIn = useMemo( () => {
    return user?.data?.id ? true : false
  }, [user])
  const isInLoadingState = useMemo(() => {
    return isLoading || (cartData?.isFetching || false);
  }, [isLoading, cart]);

  useEffect(() => {
    if (!expanded) {
      setLoading(false);
    }
  }, [expanded])

  const textTypeFields = ['text', 'email', 'tel', 'password'];

  useEffect(() => {
    (async () => {
      const states = await getStates('US');
      setStates(states);
    })()
  }, [])

  useEffect(() => {
    if (!states || !!Object.keys(fields).length || !cart?.data) return;

    const cartValue = cartData?.data[type === 'billing' ? 'billAddress' : 'shipAddress'];
    if (cartValue) {
      setFields({
        ...cartValue, 
        state: `${cartValue?.stateId}`,
        zip: cartValue?.zipcode,
        firstName: cartValue?.firstname || (cartValue?.name || '').toString().split(' ').slice(0, -1).join(' '),
        lastName: cartValue?.lastname || (cartValue?.name || '').toString().split(' ').slice(-1).join(' '),
        email: cartData?.data?.email,
      });
      setLoading(false);
      return;
    }

    const userValue = (user as any)?.data?.[type === 'billing' ? 'billAddress' : 'shipAddress'];
    if (userValue) {
      setFields({
        ...userValue, 
        state: `${userValue?.stateId}`,
        zip: userValue?.zipcode,
        firstName: userValue?.firstname || (userValue?.name || '').toString().split(' ').slice(0, -1).join(' '),
        lastName: userValue?.lastname || (userValue?.name || '').toString().split(' ').slice(-1).join(' '),
        email: cartData?.data?.email,
      });
      setLoading(false);
      return;
    }
  }, [cart, user, states])

  useEffect(() => {
    if (states && cartData?.data && !Object.keys(fields).length) {
      const values = cartData?.data[type === 'billing' ? 'billAddress' : 'shipAddress'];
      if (values) {
        setFields({
          ...values, 
          state: `${values?.stateId}`,
          zip: values?.zipcode,
          firstName: values?.firstname || (values?.name || '').toString().split(' ').slice(0, -1).join(' '),
          lastName: values?.lastname || (values?.name || '').toString().split(' ').slice(-1).join(' '),
          email: cartData?.data?.email,
        });
      }
      setLoading(false);
    }
  }, [states, cartData.data, fields])

  useEffect(() => {
    if (states && user?.data && !Object.keys(fields).length) {
      const values = (user as any).data[type === 'billing' ? 'billAddress' : 'shipAddress'];
      if (values) {
        setFields({
          ...values, 
          state: `${values?.stateId}`,
          zip: values?.zipcode,
          firstName: values?.firstname || (values?.name || '').toString().split(' ').slice(0, -1).join(' '),
          lastName: values?.lastname || (values?.name || '').toString().split(' ').slice(-1).join(' '),
          email: cartData?.data?.email,
        });
      }
      setLoading(false);
    }
  }, [states, user]);

  const handleSubmit = useCallback(async (e: FormEvent) => {
    e.stopPropagation();
    e.preventDefault();
    
  }, [])

  useEffect(() => {
    
  }, [cartData, user])

  const validateAndSubmit = useCallback(async (e) => {
    e.stopPropagation();
    e.preventDefault();
    const newErrors: string[] = [];
    
    if (!fields.firstName?.trim().length) {
      newErrors.push('Please enter your full name');
    }
    if (!fields.lastName?.trim().length) {
      newErrors.push('Please enter your full name');
    }

    const email = cartData?.data.email || user?.data?.email;

    if (!email?.length || email?.indexOf('@') === -1) {
      if (!isLoggedIn) {
        newErrors.push('Please enter your email');
      } else {
        const userRoles = (user?.data?.roles || [])

        if (isEnrolmentInCart && userRoles.includes('ambassador')) {
          newErrors.push('You are already registered as a Commons Ambassador');
        }
      }
    } else {
      const userRoles = [...(user?.data?.roles || []), ...((await (await fetch(`/.netlify/functions/exigo/userRoles?email=${email}`)).json()) || [])]

      if (isEnrolmentInCart && userRoles.includes('ambassador')) {
        newErrors.push('You are already registered as a Commons Ambassador');
      }
    }

    if (!fields.address1?.trim().length) {
      newErrors.push('Please enter your address');
    }
    if (!fields.city?.trim().length) {
      newErrors.push('Please enter your city');
    }
    if (!fields.zip?.trim().length) {
      newErrors.push('Please enter your zip');
    } else if (!fields.zip?.trim().match(/^(\d{5}-?\d{4}|\d{5}|[A-Z]\d[A-Z] ?\d[A-Z]\d)$/i)) {
      newErrors.push('Please enter a valid zip');
    }

    if (!fields.state) {
      newErrors.push('Please pick your state');
    }
    if (newErrors.length) {
      setErrors(newErrors);
      return;
    }
    setErrors([]);
    setLoading(true);
    try {
      if (isLoggedIn) {
        // Associate cart with user
        // await chordApi.associateUser(cart.data);
      }

      const addressData = {
        name: `${fields.firstName} ${fields.lastName}`.trim(),
        firstname: fields.firstName,
        lastname: fields.lastName,
        address1: fields.address1,
        address2: fields.address2,
        city: fields.city,
        state_id: fields.state,
        state: states?.states?.find((s: any) => s.id === fields.state)?.abbr,
        zipcode: fields.zip,
        phone: fields.phone,
        company: fields.company,
        country_id: `${process.env.GATSBY_ACS_API_URL}`.includes('staging') ? 233 : 731
      }
      await (cart.modifyCart as any)({
        [type === 'billing' ? 'bill_address_attributes' : 'ship_address']: {
          ...addressData
        }
      } as any);
      if (type === 'shipping') {
        await (updateOrderAddresses as any)(addressData)
      }
      await updateOrderDelivery()
      onSave(addressData)
    } catch(e: any) {
      console.error(e)
      setErrors([e.message]);
    }
    setLoading(false);
  }, [fields]);
  const buttonIsEnabled = !formFields.filter(field => field.hideIfLoggedIn && isLoggedIn ? false : true).find(field => field.required && !fields[field.name]?.trim?.());

  return expanded ? (<>
    <form onSubmit={validateAndSubmit} className={`CheckoutStep CheckoutAddress${isLoading ? ' is-loading' : ''}`}>
      <div className="CheckoutStep__loader"><Loader text="Saving your address..." /></div>
      {
        errors?.length ? <div className="CheckoutErrors">
          {errors.map((error, index) => <p key={index} className="error">{error}</p>)}
        </div>
        : null
      }
      <div className="CheckoutAddress__fields">
        
        {formFields.map((field, index) => {
          const shouldHide = field.hideIfLoggedIn && isLoggedIn;
          return <div key={`checkout_${field.name}`} className={`CheckoutField ${shouldHide ? 'hidden' : ''}`} data-key={field.name}>
            {/* <label htmlFor={field.name}
              className={`${field.required ? 'required' : ''}`}>
              {field.label}
            </label> */}
            {textTypeFields.includes(field.type) && (
              <input
                type={field.type}
                name={field.name}
                id={field.name}
                placeholder={field.label}
                value={fields[field.name]}
                onChange={(e) => {
                  setFields({
                    ...fields,
                    [field.name]: e.target.value
                  })
                }}
                required={shouldHide ? undefined : field.required}
              />
            )}
            {field.type === 'select' && (
              <select
                name={field.name}
                id={field.name}
                value={fields[field.name]}
                onChange={(e) => {
                  setFields({
                    ...fields,
                    [field.name]: e.target.value
                  })
                }}
                required={shouldHide ? undefined : field.required}
              >
                <option value="">State</option>
                {states?.states.map((option: any) => {
                  return <option key={option.id} value={option.id}>{option.name}</option>
                }
                )}
              </select>
            )}
          </div>
        })}
      </div>
      <button className="checkout__cta" type="submit" disabled={isSubmitting || !buttonIsEnabled}>Next</button>
    </form>
  </>) : (
    (cartData?.data?.shipAddress ? <div>{cartData?.data?.shipAddress ? [
      cartData.data.shipAddress.name,
      [cartData.data.shipAddress.address1, cartData.data.shipAddress.address2].filter(Boolean).join(', '),
      [cartData.data.shipAddress.city, (cartData.data.shipAddress as any)?.state?.name, cartData.data.shipAddress.zipcode].filter(Boolean).join(', '),
      (cartData as any).data.shipAddress?.country?.name
    ].map(v => <div>{v}</div>): null}</div> : null)
  )
}

interface CheckoutAddressProps {
  user: ChordUser;
  cart: any;
  expanded?: boolean;
  onSave: (data: any) => void;
  type?: 'shipping' | 'billing';
}