import styled from 'styled-components';

export const StyledCheckoutSummary = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 40px 75px 40px 40px;
  background: #F4F1ED;
  min-height: 100%;
  h3 {
    font-size: 28px;
    margin: 0 0 40px 0;
    @media (max-width: 1024px) {
      margin: 0;
    }
  }
  @media (max-width: 1024px) {
    border-radius: 0;
    order: 3;
    padding: 8px 0;
    background: var(--gray);
    margin-left:-20px;
    padding-left: 20px;
    margin-right:-20px;
    padding-right: 20px;
    margin-top: 0;
    &.is-expanded {
      padding-bottom: 24px;
    }
  }
  .CheckoutSummary__promos__text {
      font-family: 'Founders Grotesk';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      @media (max-width: 1024px) {
        font-size: 12px;
        line-height: 16px;
      }
  }
  .checkoutSummary__titleTotal {
    display: none;
    @media (max-width: 1024px) {
      display: block;
      font-family: 'Founders Grotesk';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #131313;
      margin-left: auto;
    }
  }
  .checkoutSummary__arrow {
    display: none;
    width: 24px;
    height: 24px;
    margin-left: 12px;
    border-radius: 100%;
    background: #fff;
    position: relative;
    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transition: transform 0.3s ease-in-out;
      transform: translate(-50%, -50%);
      width: 8px;
      height: auto;
      color: #131313;
    }

    @media (max-width: 1024px) {
      display: block;
    }
  }
  .CheckoutSummary__expandBtn {
    display: flex;
    width: 100%;
    appearance: none;
    padding: 0;
    background: transparent;
    border: 0;
    @media (max-width: 1024px) {
      font-family: 'Founders Grotesk';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #131313;
      position: relative;
      &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: -8px;
        left: -20px;
        right: -20px;
        height: 1px;
        background: #D9D9D9;
        opacity: 0;
      };
    }
  }
  &.is-expanded .CheckoutSummary__expandBtn:after {
    opacity: 1;
  }

  &.is-expanded .checkoutSummary__arrow svg {
    transform: translate(-50%, -50%) rotate(180deg);
  }

  .CheckoutSummary__loader {
    z-index:3;
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    width:100%;
    background: rgba(255, 255, 255, 0.5);
    display: none;
    &.is-loading {
      display: block;
    }
  }

  .CheckoutSummary__item {
    margin-bottom: 24px;
    display: grid;
    grid-template-columns: 88px 1fr;
    grid-column-gap: 24px;
    @media (max-width: 1024px) {
      grid-template-columns: 64px 1fr;
      grid-column-gap: 16px;
    }
    color: #131313;
    &__info__title {
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      @media (max-width: 1024px) {
        font-size: 16px;
        line-height: 20px;

        margin-top: auto;
        margin-bottom: auto;
      }
    }
    &__info__subtitle {
      font-size: 16px;
      opacity: 0.7;
      margin-top: 8px;
      
      @media (max-width: 1024px) {
        font-size: 12px;
        line-height: 16px;
      }

    }
    &__image {
      position: relative;
      &:after {
        padding-top: 100%;
        content: "";
        display: block;
      }
      img {
        position: absolute;
        top:0;
        left: 0;
        right: 0;
        bottom: 0;
        object-fit: cover;
        object-position: center center;
        border-radius: 10px;
        display: block;
        width: 100%;
        height: 100%;
      }
      &__quantity {
        position: absolute;
        top:-12px;
        right: -12px;
        font-size: 12px;
        font-weight: bold;
        color: #fff;
        padding: 0
        border-radius: 100%;
        line-height: 0;
        display: block;
        border-radius: 100%;
        z-index: 1;
        background-color: var(--green);
        position: absolute;
        text-align: center;
 
        &:before, &:after {
          padding-top: 50%;
          content: "";
          display: block;
          width:25px;
        }
      }
    }
    &__info {
      margin-left:5px;
      align-self: center;
      display: flex;
      position: relative;
      &__subtitle {
        display: block;
      }
      &__price {
        margin-left: auto;
        width: 33%;
        text-align: right;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: #131313;

        @media (max-width: 1024px) {
          font-size: 16px;
          line-height: 20px;
        }

        &__original {
          text-decoration: line-through;
          display: block;
          font-size: 16px;
          opacity: 0.7;
          margin-top: 8px;
          @media (max-width: 1024px) {
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
      font-weight: 400;
    }
  }

  .CheckoutSummary__totals {
    display: flex;
    flex-direction: column;
  }
  .CheckoutSummary__promos {
    padding: 32px 0 ;
    margin: 8px auto 16px auto;
    border-top: 1px solid #D9D9D9;
    border-bottom: 1px solid #D9D9D9;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    @media (max-width: 1024px) {
      padding: 16px 0;
    }
    label, &Label {
      width: 100%;
      font-weight: 400;
      display: none;
    }

    input {
      width: 75%;
      margin-bottom: 0.5rem;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #131313;
      &::placeholder {
        color: #131313;
        opacity: 0.7;
        @media (max-width: 1024px) {
          opacity: 0.5;
        }
      }
      border: 1px solid #D9D9D9;
      border-radius: 99px;
      padding: 12px 20px;
      background: #fff;

      @media (max-width: 1024px) {
        font-size: 14px;
        line-height: 24px;
      }
    }
    button {
      width: 20%;
      margin-left: 8px;
      width: calc(25% - 8px);
      margin-bottom: 0.5rem;
      background: var(--green);
      border: 1px solid var(--green);
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: #FCFAF3;

      @media (max-width: 1024px) {
        font-size: 16px;
        line-height: 19px;
        color: #FFFFFF;
      }
    }
    &__error {
      color: var(--red);
      font-weight: 400;
      margin-top: 0.5rem;
    }
  }

  .CheckoutSummary__totalItem {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;
    &:first-child {
      padding-top: 1rem;
      border-top: 1px solid var(--gray);
    }
    &__title {
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      color: #131313;

      @media (max-width: 1024px) {
        font-size: 16px;
        line-height: 20px;
      }
    }
    &__price {
      font-weight: normal;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      color: #131313;

      @media (max-width: 1024px) {
        font-size: 16px;
        line-height: 20px;
      }
    }
    &__subtitle {
      display: block;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #131313;
      opacity: 0.7;

      @media (max-width: 1024px) {
        font-size: 12px;
        line-height: 16px;
      }
    }
    &__removeBtn {
      font-weight: 200;
      cursor: pointer;
      appearance: none;
      border: 0;
      background: transparent;
      padding: 2px;
      text-decoration: underline;
      color: var(--darkGray);
      font-size: 13px;
    }
  }

  .CheckoutSummary__total {
    border-bottom: 1px solid var(--gray);
    padding: 0.5rem 0;
    display: flex;
    justify-content: space-between;
    &__title {
      font-weight: 400;
      font-size: 1.25rem;

      @media (max-width: 1024px) {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
      }
    }

    &__price {
      font-weight: 500;
      font-size: 1.5rem;

      @media (max-width: 1024px) {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
      }
    }
  }

  .CheckoutSummary__footer {
    border-top: 1px solid #D9D9D9;
    padding-top: 32px;
    display: flex;
    gap:16px;

    svg {
      width: 24px;
      height: 24px;
      margin: 0 0 auto 0;
    }
    color: #34563B;

    &__title {
      font-family: 'Founders Grotesk';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 4px;

      @media (max-width: 1024px) {
        font-size: 16px;
        line-height: 24px;
      }
    }

    &__text {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;

      @media (max-width: 1024px) {
        font-size: 12px;
        line-height: 16px;
      }
    }

    a {
      color: #34563B;
      text-decoration: underline;
    }
  }

  @media (max-width: 1024px) {
    .CheckoutSummary__items {
      margin-top: 24px;
    }
    :not(.is-expanded) {
      .CheckoutSummary__items, .CheckoutSummary__promosContainer, .CheckoutSummary__totals {
        display: none;
      }
    }

`;
