import styled from 'styled-components';
import { pxtorem } from '../../utils/tools';

export const StyledCheckout = styled.div`
  display: grid;
  grid-template-columns: 1fr minmax(250px, 560px);
  grid-template-areas: "main summary";
  column-gap: 6rem;;
  width: 90%;
  max-width: 1920px;
  margin: 0 auto;
  @media (max-width: 1024px) {
    /* grid-template-columns: 1fr; */
    /* grid-template-areas: "summary" "main" ; */
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    > * {
      padding-left: 5%;
      padding-right: 5%;
    }
  }

  min-height: calc(100vh - 120px);

  .checkout__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 45px auto;
    grid-column: 1/3;
    h1 {
      font-family: var(--secondaryFont);
      font-style: italic;
      font-weight: 300;
      font-size: 52px;
      line-height: 58px;
      letter-spacing: -0.01em;
      color: #131313;

      @media (max-width: 768px) {
        font-size: 34px;
        line-height: 38px;
        margin: 0 0 16px 0;
      }
    }
    p {
      font-style: normal;
    }
    
  }
  .CheckoutErrors {
    grid-column: span 2;
    background: var(--coral);
    color: var(--white);
    border-radius: 10px;
    padding: 1rem;
    margin: 0 0 1rem 0;
    p {
      margin: 0 0 0.5rem 0;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .sub-notice {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    color: rgba(19, 19, 19, 0.7);
    margin-top: 20px;
    a {
      color: #34563B;
    }
  }
  .checkout__summary {
    grid-area: summary;
    background:  var(--gray);
  }
  .checkout__main {
    margin-left: calc(35px + var(--gutter));
    position: relative;
    @media (max-width: 768px) {
      margin-left: 0;
      margin: 32px 0 0 0;
    }
  }

  .checkout__title {
    font-family: var(--secondaryFont);
    font-style: italic;
    font-weight: 300;
    font-size: 52px;
    line-height: 58px;
    letter-spacing: -0.01em;
    color: #131313;

    @media (max-width: 768px) {
      font-size: 34px;
      line-height: 38px;
      margin: 0 0 16px 0;
    }
  }
  .checkout__cta {
    --cta-color: var(--orange);
    margin-top: 24px;
    background: var(--cta-color);
    color: var(--white);
    padding: 12px 40px;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    border: 1px solid var(--cta-color);
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out, border-color 0.3s ease-in-out;
    cursor: pointer;
    border-radius: 99px;
    &[disabled] {
      --cta-color: #D9D9D9;
    }
    &:hover {
      background: transparent;
      color: var(--cta-color);
    }
  }
  .checkout__step {
    padding: 24px 0 8px 0;
    border-top: 1px solid #D9D9D9;

    &:last-child {
      border-bottom: 1px solid #D9D9D9;
    }

    display: grid;
    grid-template-columns: 32px 1fr;
    gap: 16px;
    grid-template-areas: "number title" "number content";
    &--expanded {
      @media (max-width: 768px) {
        grid-template-areas: "number title" "content content";
      }
    }

    --number-border: #D0D0D0;
    --step-opacity: 0.3;
    
    &--expanded {
      --step-opacity: 1;
    }
    
    &--completed .checkout__step__number {
      background: #E7F7D3;
      span {
        opacity: 0;
      }
      svg {
        opacity: 1;
      }
    }

    &__number {
      grid-area: number;
      border: 1px solid #D0D0D0;
      border-radius: 100%;
      height: 32px;
      width: 32px;
      position: relative;
      transition: background-color 0.3s ease-in-out, opacity 0.3s ease-in-out;
      @media (max-width: 768px) {
        height: 24px;
        width: 24px;
      }
      >span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        transition: opacity 0.3s ease-in-out;
        color: #131313;
        @media (max-width: 768px) {
          font-size: 14px;
        }
      }
      svg {
        opacity: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width:50%;
        height:50%;
        color: #34563B;
        transition: opacity 0.3s ease-in-out;
      }
    }

    &__title {
      grid-area: title;
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 32px;
      color: #131313;
      span {
        opacity: var(--step-opacity);
      }
      display: flex;

      @media (max-width: 768px) {
        font-size: 16px;
        line-height: 24px;
      }
    }
    &__edit {
      cursor: pointer;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #34563B;
      position: relative;
      padding: 0;
      margin: auto 0 auto auto;
      background: none;
      border: none;

      @media (max-width: 768px) {
        font-size: 12px;
        padding: 4px 0;
      }

      &:after, &:before {
        content: '';
        position: absolute;
        bottom: 0;
        height: 1px;
        background: currentColor;
        transition: width 0.3s ease-in-out;
        @media (max-width: 768px) {
          bottom: 4px;
        }
      }
      &:before {
        right: 0;
        width: 100%;
        transition-delay: 0s;
      }
      &:after {
        left: 0;
        transition-delay: 0.3s;
        width: 0;

      }
      &:hover:before {
        width: 0;
      }
      &:hover:after {
        width: 100%;
      }
    }

    &__content {
      grid-area: content;
      opacity: var(--step-opacity);
      margin-bottom: 16px;
      &:empty {
        display: none;
      }
      @media (max-width: 768px) {
        font-size: 16px;
      }
    }
  }

  .checkoutContact input {
    width: 100%;
    border-radius: 99px;
    border: 1px solid #D9D9D9;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #131313;
    padding: 12px 20px;
    margin: 16px 0 0 0;

    @media (max-width: 768px) {
      margin-top: 0;
    }

    &::placeholder {
      color: #131313;
      opacity: 0.5;
    }
  }

  .CheckoutStep {
    position: relative;
    max-width: 620px;
    &__loader {
      position: absolute;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
      pointer-events: none;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255,255,255,0.8);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 3;
      > div {
        padding: 0;
      }
    }
    &__tooltip {
      cursor: pointer;
      color: #34563B;
      border: none;
      background: none;
      padding: 0;
      position: relative;
      &:after, &:before {
        content: '';
        position: absolute;
        bottom: 0;
        height: 1px;
        background: currentColor;
        transition: width 0.3s ease-in-out;
      }
      &:before {
        right: 0;
        width: 100%;
        transition-delay: 0s;
      }
      &:after {
        left: 0;
        transition-delay: 0.3s;
        width: 0;

      }
      &:hover:before {
        width: 0;
      }
      &:hover:after {
        width: 100%;
      }
    }
    label {
      > span {
        font-family: 'Founders Grotesk';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #131313;
        display: flex;
        justify-content: space-between;

        @media (max-width: 768px) {
          font-size: 12px;
          line-height: 16px;
          color: #131313;
          margin-bottom: 16px;
        }
      }
    }
    &.is-loading {
      .CheckoutStep__loader {
        opacity: 1;
        pointer-events: all;
      }
    }
  }

  .is-loading .CheckoutAddress__fields {
    opacity: 0.5;
    pointer-events: none;
  }
  .CheckoutAddress{
    &__fields {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-template-areas:
        "firstName firstName firstName firstName lastName lastName lastName lastName"
        "phone phone phone phone phone phone phone phone"
        "address1 address1 address1 address1 address1 address1 address1 address1"
        "address2 address2 address2 address2 address2 address2 address2 address2"
        "city city city state state state zip zip";
      @media (max-width: 768px) {
        grid-template-columns: 1fr 1fr;
        grid-template-areas: 
          "firstName lastName"
          "phone phone"
          "address1 address1"
          "address2 address2"
          "city city"
          "state zip";
      }
      gap: 12px;
      [data-key="firstName"] {
        grid-area: firstName;
      }
      [data-key="lastName"] {
        grid-area: lastName;
      }
      [data-key="phone"] {
        grid-area: phone;
      }
      [data-key="address1"] {
        grid-area: address1;
      }
      [data-key="address2"] {
        grid-area: address2;
      }
      [data-key="city"] {
        grid-area: city;
      }
      [data-key="state"] {
        grid-area: state;
      }
      [data-key="zip"] {
        grid-area: zip;
      }

      .CheckoutField {
        position: relative;
        input, select {
          width: 100%;
        }
      }

      input, select {
        border-radius: 99px;
        border: 1px solid #D9D9D9;
        padding: 12px 20px;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #131313;
        opacity: 1;
        &::placeholder {
          opacity: 0.5;
        }
      }
      select {
        padding-right: 40px;
      }
    }
  }

  .CheckoutPayment {
    [id="rswp-card-button"] {
      background: var(--orange);
      border-radius: 99px;
      font-family: 'Founders Grotesk';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: #FFFFFF;
      padding: 16px;
      border: 1px solid var(--orange);
      transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
      &:hover {
        background: transparent;
        color: var(--orange);
      }
    }
  }

  .CheckoutAmbassador {
    
    &__results {
      border: 1px solid #D9D9D9;
      border-radius: 12px;
      position: relative;
      padding: 12px 12px 8px 12px;
      margin-top: 16px;
    }
    &__resultsList {
      margin: 0;
      padding: 0;
      position: relative;
    }
    &__result {
      display: block;
      padding: 8px 12px;
      background: transparent;
      border: none;
      cursor: pointer;
      width: 100%;
      text-align: left;
      border-radius: 12px;
      transition: background 0.3s ease-in-out;
      &:hover {
        background: #E7F7D3;
      }
      @media (max-width: 768px) {
        padding: 8px 0;
      }
    }
    &__resultsCount {
      font-family: var(--primaryFont);
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #7D7D7D;
      margin: 0 0 12px 12px;
      padding: 0 0 12px 0;
      &:after {
        position: absolute;
        width:100%;
        left:0;
        height: 1px;
        content: "";
        display: block;
        background: #D9D9D9;
        margin-top: 12px;
      }
      margin-bottom: 12px;

      @media (max-width: 768px) {
        padding: 0;
        margin: 0;
        margin-bottom: 28px;
      }
    }
    &__form {
      margin-top: 48px;
    }
    &__input {
      position: relative;
      input {
        padding: 12px 16px 12px 40px;
        &::placeholder {
          color: #13131346;
          transition: color 0.3s ease-in-out;
        }
        border: 1px solid #D9D9D9;
        transition: border 0.3s ease-in-out;
        border-radius: 99px;
        &:focus {
          border: 1px solid #131313;
          outline: none;
        }
      }
      svg {
        position: absolute;
        top: 50%;
        left: 16px;
        width: 12px;
        height: 12px;
        margin-top: 2px;
        transform: translateY(-50%);
        transition: width 0.3s ease-in-out, height 0.3s ease-in-out;
      }
      &.has-value svg, input:focus + svg {
        width: 12px;
        height: 12px;
      }
    }
  }
`;
