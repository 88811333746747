import { useAuth, useCart, useUser } from '@chordcommerce/gatsby-theme-autonomy';
import React, { useEffect, useMemo, useState } from 'react';
import CheckoutSummary from './CheckoutSummary';
import { StyledCheckout } from './Checkout.style';
import { CheckoutAmbassador } from './CheckoutAmbassador';
import { CheckoutContact } from './CheckoutContact';
import { CheckoutAddress } from './CheckoutAddress';
import { CheckoutPayment } from './CheckoutPayment';
import { Cta } from '../Generic/Cta/Cta';
const CHECKOUT_STEPS = [
  'CONTACT',
  'AMBASSADOR',
  'SHIPPING',
  'PAYMENT',
]

const CHECKOUT_STEPS_LABELS: Record<typeof CHECKOUT_STEPS[number], string> = {
  CONTACT: 'Enter Your Contact Details',
  AMBASSADOR: 'Do You Shop with an Ambassador?',
  SHIPPING: 'Enter Your Billing + Shipping Details',
  PAYMENT: 'Enter Your Payment Details',
}

const checkmarkIcon = <svg viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.33333 4.99999L4.66666 8.33332L11.3333 1.66666" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


const CheckoutStep: React.FC<StepProps> = ({step, expanded, children, completed, component, editAction, user, cart, onSave}) => {
  const classes = ['checkout__step']
  if (expanded) {
    classes.push('checkout__step--expanded')
  }
  if (completed) {
    classes.push('checkout__step--completed')
  }
  const index = useMemo(() => CHECKOUT_STEPS.indexOf(step), [step]);
  const Component = component;
  return (
    <div className={classes.join(' ')}>
      <div className="checkout__step__number"><span>{index + 1}</span>{checkmarkIcon}</div>
      <div className="checkout__step__title"><span>{CHECKOUT_STEPS_LABELS[step]}</span>{completed && !expanded ? <button className="checkout__step__edit" onClick={editAction}>Edit</button> : null}</div>
      <div className="checkout__step__content">
        {component ? <Component expanded={expanded} completed={completed} onSave={onSave} user={user} cart={cart} step={step} /> : null}
        {children}
      </div>
    </div>
  )
}

export const Checkout: React.FC<CheckoutProps> = () => {
  const [currentStep, setCurrentStep] = useState<typeof CHECKOUT_STEPS[number]>(CHECKOUT_STEPS[0]);
  const [isLoading, setIsLoading] = useState(true);
  const { isLoggedIn } = useAuth();
  const { user, modifyUser } = useUser();
  const chordCart = useCart();
  const cart = chordCart.cart;
  const [hasLoaded, setHasLoaded] = useState(false);
  const enabledSteps = useMemo(() => {
    const enabledSteps = [...CHECKOUT_STEPS];
    // if (isLoggedIn && user?.data?.roles?.includes('ambassador')) {
    //   enabledSteps.splice(1, 1);
    // }
    return enabledSteps;
  }, [isLoggedIn, user])

  useEffect(() => {
    setIsLoading(!cart || cart?.isFetching);
    if (cart && !cart.isFetching && !hasLoaded) {
      setHasLoaded(true);
      const hasEmail = Boolean(cart?.data?.email);
      const hasShippingAddress = Boolean(cart?.data?.shipAddress);
      const hasAmbassador = Boolean(cart?.data?.metadata?.ambassadorId);
      const validationMap = {
        CONTACT: hasEmail,
        SHIPPING: hasShippingAddress,
        AMBASSADOR: hasAmbassador,
      }
      const nextStep = CHECKOUT_STEPS.find(step => !(validationMap as any)[step]);
      setCurrentStep(nextStep || CHECKOUT_STEPS[0]);
    }
  }, [cart, user])
  console.log({data: cart.data})
  if (cart?.data && cart?.data?.lineItems?.length === 0) {
    return (
      <StyledCheckout>
        <div className="checkout__empty">
          <h1 className="checkout__empty__title">Your cart is empty</h1>
          <p className="checkout__empty__text">You have no items in your cart.</p>
          <Cta variant='primary' href="/shop">Continue shopping</Cta>
        </div>
      </StyledCheckout>
    )
  }
  
  return (
    <StyledCheckout>
      <div className="checkout__summary">
        <CheckoutSummary chordCart={chordCart} user={user} />
      </div>
      <div className="checkout__main">
        <div className="checkout__header">
          <h1 className="checkout__title">Checkout</h1>
        </div>
        <div className="checkout__content">
          <div className="checkout__steps">
            {enabledSteps.map((step, index) => {
              let component = undefined;
              switch (step) {
                case 'CONTACT':
                  component = CheckoutContact
                  break;
                  case 'AMBASSADOR':
                    component = CheckoutAmbassador
                    break;
                  case 'SHIPPING':
                    component = CheckoutAddress
                    break;
                  case 'PAYMENT':
                    component = CheckoutPayment
                    break;
              }
              return (
                <CheckoutStep
                  key={step}
                  step={step}
                  expanded={step === currentStep}
                  completed={index < enabledSteps.indexOf(currentStep)}
                  component={component as any}
                  user={user}
                  cart={chordCart}
                  editAction={() => setCurrentStep(step)}
                  onSave={() => setCurrentStep(enabledSteps[index + 1])}
                />
              )}
            )}
          </div>
        </div>
      </div>
    </StyledCheckout>
  )
}

interface CheckoutProps {}
interface StepProps {
  step: typeof CHECKOUT_STEPS[number];
  expanded?: boolean;
  completed?: boolean;
  children?: React.ReactNode;
  component?: React.ComponentType<StepProps>;
  editAction?: () => void;
  onSave?: () => void;
  user: ChordUser,
  cart: any,
}