import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import Layout from '~/components/Layout/Layout'
import Metadata from '~/components/Metadata'
import { Router } from '@reach/router'
import AccountLoginPage from '../components/Account/Login/Page'
import PrivateRoute from '../components/Account/PrivateRoute'
import AccountProfilePage from '../components/Account/Profile/Page'
import AccountSubscriptionPage from '../components/Account/Subscription/Page'
import AccountSubscriptionDetailPage from '../components/Account/Subscription/Detail/Page'
import AccountPage from '../components/Account/Page'
import {Checkout} from '../components/Checkout/Checkout';
const CheckoutPage = () => {
  const translate = useTranslate()

  return (
    <Layout>
      <Metadata title={"Checkout"} />
      <Checkout />
    </Layout>
  )
}

export default CheckoutPage
