import { Tooltip } from '@mantine/core';
import React, { useState, useEffect, useCallback, FormEventHandler, FormEvent, useMemo } from 'react';
import { exigo } from '~/utils/exigo';
import Loader from '../Loader/Loader';

const searchIcon = <svg  viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.932 12.4171L9.94806 9.43311C10.8049 8.44613 11.257 7.16974 11.2104 5.8605C11.1624 4.51153 10.5887 3.2349 9.61184 2.30336C8.63506 1.3719 7.33261 0.859297 5.98292 0.875367C4.63329 0.891359 3.34334 1.43466 2.38903 2.38906C1.43465 3.34353 0.891367 4.63349 0.875363 5.98297L1.00035 5.98445L0.875363 5.98297C0.859371 7.33261 1.37189 8.63505 2.30335 9.6119L2.30335 9.61191C3.2349 10.5887 4.51143 11.1624 5.86045 11.2105C7.16955 11.2571 8.44594 10.8049 9.43304 9.94812L12.5061 13.0212L12.5061 13.0213L12.5077 13.0228C12.6506 13.1607 12.8778 13.1589 13.0183 13.0183C13.1588 12.8778 13.1609 12.6506 13.0227 12.5077L12.9336 12.4155L12.932 12.4171ZM1.64298 6.06417C1.64301 4.89166 2.10884 3.76726 2.93789 2.93811C3.76706 2.10902 4.89156 1.64318 6.06403 1.64318C7.23651 1.64318 8.36121 2.10903 9.19015 2.9381L9.27855 2.84972L9.19016 2.9381C10.0192 3.76728 10.4851 4.89178 10.4851 6.06426C10.4851 7.23675 10.0192 8.36146 9.19017 9.19041C8.36111 10.0193 7.23659 10.4853 6.06417 10.4853C4.892 10.484 3.76817 10.0178 2.9394 9.18892C2.11061 8.36005 1.64437 7.23628 1.64298 6.06417Z" fill="currentColor" stroke="currentColor" stroke-width="0.25"/>
</svg>

export const CheckoutAmbassador: React.FC<CheckoutAmbassadorProps> = ({expanded, user, cart, onSave}) => {
  const [email, setEmail] = useState(cart?.data?.email || user?.data?.email);
  const isValidEmail = email && email.includes('@');
  const [error, setError] = useState('');
  const [isDirty, setIsDirty] = useState(false);
  const cartData:ChordCart = cart.cart;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [value, setValue] = useState('');
  const [allAmbassadors, setAllAmbassadors] = useState([]);
  const matchingResults = useMemo(() => {
    if (!value) {
      return [];
    }
    return allAmbassadors.filter((ambassador: any) => ambassador.name.toLowerCase().includes(value.toLowerCase())).slice(0, 8);
  }, [value, allAmbassadors])
  const [selectedAmbassador, setSelectedAmbassador] = useState(null);
  const [hasLoadedAmbassadors, setHasLoadedAmbassadors] = useState(false);
  const selectedAmbassadorObject = useMemo(() => {
    return !selectedAmbassador ? null : allAmbassadors.find((ambassador: any) => `${ambassador.id}` === `${selectedAmbassador}`);
  }, [selectedAmbassador, allAmbassadors])

  useEffect(() => {
    if (hasLoadedAmbassadors || cartData?.isFetching) {
      return
    }
    setHasLoadedAmbassadors(true);
    (async () => {
      setAllAmbassadors(await exigo('ambassadors'));
      setSelectedAmbassador(cartData?.data?.metadata?.ambassadorId || null);
    })();
  }, [expanded, hasLoadedAmbassadors, cartData])


  const handleSubmit = useCallback(async (e: FormEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setIsSubmitting(true);
    await cart.modifyCart({
      metadata: {
        ambassadorId: selectedAmbassadorObject?.id ? `${selectedAmbassadorObject?.id}` : null,
        ambassador_id: selectedAmbassadorObject?.id ? `${selectedAmbassadorObject?.id}` : null,
      }
    })
    setIsSubmitting(false);
    onSave(cartData.data);

    return false
  }, [selectedAmbassadorObject])

  useEffect(() => {
    if (isDirty) return;
    if (cartData?.isFetching) return;
    if (cartData.data?.email) {
      setEmail(cartData.data.email);
      return;
    }
    if (user.data?.email) {
      setEmail(user.data.email);
      return;
    }
  }, [cartData, user, isDirty])

  useEffect(() => {
    if (!isSubmitting) return;
    console.log('...is submitting!')
    if (cartData?.isFetching) return;
    if (cartData?.error) {
      setError((cartData.error as any)?.message || 'There was an error saving your contact details');
      setIsSubmitting(false);
      return;
    }
    if (isValidEmail && cartData?.data?.email === email) {
      onSave(cartData.data);
      setIsSubmitting(false);
    }
  }, [cartData, isSubmitting, email, setIsSubmitting, isValidEmail])
  return expanded ? (<>
    <form onSubmit={handleSubmit} className={`CheckoutStep CheckoutAmbassador${isSubmitting ? ' is-loading' : ''}`}>
      <div className="CheckoutStep__loader"><Loader text="Saving your ambassador selection" /></div>
      <label>
        <span>
          <span>Did an Ambassador help you with this purchase?</span>
          <span>
            <Tooltip 
              multiline
              transition="fade"
              withArrow
              width={370}
              events={{ hover: true, focus: true, touch: true }}
              styles={{
                tooltip: {
                  border: '1px solid #D0D0D0', 
                  background: '#F4F1ED', 
                  color: '#393D2A',
                  marginTop: `16px`,
                  fontFamily: `'Founders Grotesk'`,
                  fontStyle: `normal`,
                  fontWeight: 400,
                  fontSize: `16px`,
                  lineHeight: `22px`,
                  padding: '12px 16px',
                  borderRadius: `12px`

                },
                arrow: {
                  border: '1px solid #D0D0D0',
                  height: `17px !important`,
                  width: `17px !important`,
                  marginTop: `-7px`
                }
              }}
              position="bottom"
              label={`A Commons Ambassador is like a coach for all your health and wellness needs. Did an Ambassador help you with your purchase? If so, enter their name below. If not, choose \"next\" and finish checkout.`}
            >
              <button type="button" className="CheckoutStep__tooltip">What's this?</button>
            </Tooltip>
            </span>
          </span>
        {error ? <div className="error">{error}</div> : null}
        <div className={`CheckoutStep__input ${value ? 'has-value' : ''} CheckoutAmbassador__input`}>
          <input type="text" autoComplete="off" placeholder="Search for an ambassador by typing their name..." value={isDirty ? value : selectedAmbassadorObject?.name || value} onChange={(e) => [setValue(e.target.value), setSelectedAmbassador(null), setIsDirty(true)]} />
          {searchIcon}
        </div>
      </label>
      {value && !selectedAmbassador? <div className="CheckoutAmbassador__results">
        <div className="CheckoutAmbassador__resultsCount">{!matchingResults.length ? 'No results' : `Showing ${matchingResults.length} result${matchingResults.length > 1 ? 's' : ''}`}</div>
        <div className="CheckoutAmbassador__resultsList">
          {matchingResults.map((ambassador: any) => (
            <button className="CheckoutAmbassador__result" key={ambassador.id} onClick={() => [setSelectedAmbassador(ambassador.id), setValue(ambassador.name)]}>{ambassador.name}</button>
          ))}
        </div>
      </div> : null}
      <button className="checkout__cta" type="submit" disabled={isSubmitting || (value && !selectedAmbassador)}>Next</button>
    </form>
  </>) : (
    selectedAmbassadorObject?.name || user?.data?.roles?.includes('ambassador') ? <div>{user?.data?.roles?.includes('ambassador') ? 'You are shopping as an ambassador. This order will count towards your personal volume.' : selectedAmbassadorObject?.name}</div> : null
  )
}

interface CheckoutAmbassadorProps {
  user: ChordUser;
  cart: any;
  expanded?: boolean;
  onSave: (data: any) => void;
}