import React, { useState, useEffect, useCallback, FormEventHandler, FormEvent } from 'react';
import Loader from '../Loader/Loader';

export const CheckoutContact: React.FC<CheckoutContactProps> = ({expanded, user, cart, onSave}) => {
  const [email, setEmail] = useState(cart?.data?.email || user?.data?.email);
  const isValidEmail = email && email.includes('@');
  const [error, setError] = useState('');
  const [isDirty, setIsDirty] = useState(false);
  const cartData:ChordCart = cart.cart;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit = useCallback(async (e: FormEvent) => {
    e.stopPropagation();
    e.preventDefault();
    if (!isValidEmail) {
      setError('Please enter a valid email address');
      return false;
    }
    setError('');
    setIsSubmitting(true);
    await cart.modifyCart({
      email
    })
    return false
  }, [email])

  useEffect(() => {
    if (isDirty) return;
    if (cartData?.isFetching) return;
    if (cartData.data?.email) {
      setEmail(cartData.data.email);
      return;
    }
    if (user.data?.email) {
      setEmail(user.data.email);
      return;
    }
  }, [cartData, user, isDirty])

  useEffect(() => {
    if (!isSubmitting) return;
    console.log('...is submitting!')
    if (cartData?.isFetching) return;
    if (cartData?.error) {
      setError((cartData.error as any)?.message || 'There was an error saving your contact details');
      setIsSubmitting(false);
      return;
    }
    if (isValidEmail && cartData?.data?.email === email) {
      onSave(cartData.data);
      setIsSubmitting(false);
    }
  }, [cartData, isSubmitting, email, setIsSubmitting, isValidEmail])
  return expanded ? (<>
    <form onSubmit={handleSubmit} className={`CheckoutStep checkoutContact${isSubmitting ? ' is-loading' : ''}`}>
      <div className="CheckoutStep__loader"><Loader text="Saving your contact details" /></div>
      {user?.data?.email ? <div className="CheckoutStep__userEmail">You are currently logged in. Order updates will be sent to {user.data.email}.</div> :(
      <label>
        <span>Where would you like your order updates sent?</span>
        {error ? <div className="error">{error}</div> : null}
        <input placeholder='Your Email Address' readOnly={!!user?.data.email} type="email" required value={email} onChange={e => [setEmail(e.target.value), setIsDirty(true)]} />
      </label>
      )}
      <button className="checkout__cta" type="submit" disabled={!isValidEmail || isSubmitting}>Next</button>
    </form>
  </>) : (
    <div>{cartData?.data?.email || user?.data?.email}</div>
  )
}

interface CheckoutContactProps {
  user: ChordUser;
  cart: any;
  expanded?: boolean;
  onSave: (data: any) => void;
}